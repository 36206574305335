import { Box, Divider, Typography } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "../../Redux/reduxHooks";
import { selectUser, UserState } from "../../Redux/Slices/userSlice";
import { allRoutes } from "../../Routes/AllRoutes";
import { getInstallerCompanyDetails, updateInstallerCompany } from "../../Services/dashboardService";
import { getProfile } from "../../Services/profileService";
import CustomButton from "../Common/CustomButton";
import CustomTextField, { Asterisk } from "../Common/CustomTextField";
import { PlaceType } from "../Common/GoogleMapsTextField";
import ImageUploader from "../Common/ImageUploader";
import PageLayout from "../PageLayout/PageLayout";

interface AccountSettingsData extends UserState {
	_id?: string;
	changePassword?: string;
	addressObject?: PlaceType | null;
}

const defaultData = {
	_id: "",
	name: "",
	email: "",
	addressObject: null,
	bill: "",
	phone: "",
	password: "",
	changePassword: "",
};

const EditCompanyInformation = () => {
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [data, setData] = useState<AccountSettingsData>(defaultData);
	const [errors, setErrors] = useState<AccountSettingsData>(defaultData);
	const [loading, setLoading] = useState<boolean>(false);
	const [profilePicture, setProfilePicture] = useState<any>("");

	useEffect(() => {
		if (user.companyId) {
			getDetails();
		}
	}, [user.companyId]);

	const getDetails = async () => {
		setLoading(true);
		try {
			const { data: userData } = await getInstallerCompanyDetails((user.companyId || "")?.toString());

			const currentData = {
				_id: userData?._id || "",
				name: userData?.name || "",
				email: userData?.email || "",
				addressObject: {
					description: userData.address,
					structured_formatting: {
						main_text: userData.address,
						secondary_text: userData.address,
					},
				},
				phone: userData?.phone_no || "",
			};
			setData(currentData);
			setProfilePicture(userData?.image || userData?.ImageUrl || "");
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setData((state) => ({ ...state, [name]: value }));
		setErrors((state) => ({ ...state, [name]: "" }));
	};

	const handleAddress = (value: PlaceType | null) => {
		setData((state) => ({ ...state, addressObject: value }));
		setErrors((state) => ({ ...state, address: "" }));
	};

	const handleSelectImage = (image: any) => {
		setProfilePicture(image);
	};

	const validateData = () => {
		const updatedErrors = { ...errors };

		updatedErrors.name = data.name ? "" : "Name cannot be empty";
		// updatedErrors.email = data.email
		// 	? !EmailValidator.validate(data.email)
		// 		? "Enter a valid email"
		// 		: ""
		// 	: "Email cannot be empty";
		// updatedErrors.address = data.addressObject?.description ? "" : "Address cannot be empty";

		setErrors(updatedErrors);
		return !Object.values(updatedErrors).find(Boolean);
	};

	const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;

		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("ImageUrl", profilePicture ?? "");
			formData.append("name", data.name ?? "");
			// formData.append("email", data.email ?? "");
			// formData.append("address", data.addressObject?.description ?? "");
			// formData.append("phone_no", data.phone ?? "");

			await updateInstallerCompany(data?._id || "", formData);

			toast.success("Installer Company updated successfully!");
			await dispatch(getProfile() as any);
			navigate(allRoutes.DASHBOARD);
		} catch (error: any) {
			let specificError = error;
			if (specificError.includes("This email already exists.")) {
				specificError = "A company with this email already exists.";
				setErrors({ ...errors, email: specificError });
			} else {
				toast.error(specificError);
			}
		}
		setLoading(false);
	};

	const handleCancel = () => {
		navigate(-1);
	};

	return (
		<PageLayout loading={loading}>
			<Typography variant="h5">Company Details</Typography>
			<Typography fontSize={15} mt={10}>
				Update details of your installer company
			</Typography>
			<Divider sx={{ mt: 14, mb: 24 }} />

			<form onSubmit={handleUpdate}>
				<Box
					display="grid"
					gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
					gap={{ xs: 10, md: 32 }}
					alignItems="center"
				>
					<Box alignSelf="flex-start">
						<Typography variant="h5">Company Logo</Typography>
						<Typography fontSize={15} mt={10}>
							This will be displayed on installer company's dashboard
						</Typography>
					</Box>
					<Box>
						<ImageUploader isLogo onUpdate={handleSelectImage} imageFile={profilePicture} />
					</Box>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Company Name <Asterisk />
					</Typography>
					<CustomTextField
						error={errors && errors.name ? "Site Title can not be empty" : ""}
						onChange={handleOnChange}
						name="name"
						value={data.name}
						placeholder="Site Title"
					/>

					<Box />
					<Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
						<CustomButton variant="outlined" color="secondary" onClick={handleCancel}>
							Cancel
						</CustomButton>
						<CustomButton type="submit">Save Changes</CustomButton>
					</Box>
				</Box>
			</form>
		</PageLayout>
	);
};

export default EditCompanyInformation;
