import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "../../Redux/reduxHooks";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { selectUser } from "../../Redux/Slices/userSlice";
import { allRoutes } from "../../Routes/AllRoutes";
// import { downloadSampleCsv, uploadCustomersCsv } from "../../Services/csvService"; // Import your CSV APIs
import { getTechnicianCustomers } from "../../Services/dashboardService";
import {
	downloadAssignedCustomersCsv,
	downloadCustomersProposalCsv,
	downloadUnAssignedCustomersCsv,
} from "../../Services/downloadCustomersCsv";
import { downloadSampleCsv, uploadCustomersCsv } from "../../Services/importCustomerCsv";
import {
	getAllAssignedCustomers,
	getAllOldCustomers,
	getAllUnassignedCustomers,
	getCustomersOfRepresentative,
} from "../../Services/installersService";
import {
	isAdminLoggedIn,
	isManagerLoggedIn,
	isOfficeManagerLoggedIn,
	isRepresentativeLoggedIn,
	isTechnicianLoggedIn,
} from "../../Services/userService";
import { sendWelcomeEmailToCustomer } from "../../Services/welcomeEmailService";
import CustomButton from "../Common/CustomButton";
import CustomTableOptions from "../Common/CustomTableOptions";
import AssignRepresentativeDialog from "../Dashboard/AssignRepresentativeDialog";
import { TableBlock } from "../Dashboard/Dashboard";
import PageLayout from "../PageLayout/PageLayout";
import { customerTableHeaders, downloadPDFFiles } from "../Representative/RepresentativeDetails";

export const tableHeaders = [
	{ text: "ID", key: "sequentialId", showEllipses: true, maxWidth: 75 },
	{ text: "Name", key: "name" },
	{ text: "Email address", key: "email" },
];

const Customers = () => {
	const navigate = useNavigate();
	const user: any = useSelector(selectUser);
	const textSnippets = useSelector(selectAllTextSnippets);

	const isAdmin = isAdminLoggedIn();
	const isOfficeManager = isOfficeManagerLoggedIn();
	const isManager = isManagerLoggedIn();
	const isRepresentative = isRepresentativeLoggedIn();
	const isTechnician = isTechnicianLoggedIn();

	const [loading, setLoading] = useState<boolean>(false);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [selectedAgingCustomer, setSelectedAgingCustomer] = useState({ value: 90, text: " 90 days" });
	const [data, setData] = useState<{
		unassignedCustomers?: Array<any>;
		assignedCustomers?: Array<any>;
		oldCustomers?: Array<any>;
	}>({
		unassignedCustomers: [],
		assignedCustomers: [],
	});
	const [assigningCustomer, setAssigningCustomer] = useState<{ text: string; id: string } | null>(null);

	useEffect(() => {
		getAllUsers();
	}, [user, selectedAgingCustomer]);

	const sendWelcomeEmail = (userId: string) => async () => {
		try {
			const payload = {
				userId,
			};
			const welcomeEmail = await sendWelcomeEmailToCustomer(payload);
			toast.success(welcomeEmail?.data?.welcomeEmail?.message);
		} catch (error: any) {
			toast.error(error);
		}
	};

	const getAllUsers = async () => {
		if (!user.id) return;

		setLoading(true);
		try {
			if (isAdmin || isOfficeManager || isManager) {
				const { data: unassigned } = await getAllUnassignedCustomers();
				const { data: assigned } = await getAllAssignedCustomers();
				const { data: oldCustomers } = await getAllOldCustomers(selectedAgingCustomer.value.toString());

				setData({ unassignedCustomers: unassigned, assignedCustomers: assigned, oldCustomers });
			} else if (isRepresentative) {
				const { data: oldCustomers } = await getAllOldCustomers("90");
				const { data } = await getCustomersOfRepresentative((user.id || "")?.toString());
				setData({ assignedCustomers: data, oldCustomers });
			} else if (isTechnician) {
				const { data } = await getTechnicianCustomers((user.id || "")?.toString());
				setData({ assignedCustomers: data });
			}
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const getRemainingCustomers = async () => {
		try {
			const { data: unassigned } = await getAllUnassignedCustomers();
			const { data: assigned } = await getAllAssignedCustomers();
			setData({ unassignedCustomers: unassigned, assignedCustomers: assigned });
		} catch (error: any) {
			toast.error(error);
		}
	};

	const openDialog = (props: { text: string; id: string }) => setAssigningCustomer(props);
	const closeDialog = () => setAssigningCustomer(null);

	const unassignedCustomersTableHeader = [
		...customerTableHeaders,
		{
			text: "Welcome Email",
			key: "welcomeEmail",
			sortable: true,
			showEllipses: true,
			maxWidth: 130,
			customComponent: (props: any) => (
				<CustomButton
					sx={{ py: 8, px: 14 }}
					variant="outlined"
					onClick={(event) => {
						event.stopPropagation();
						sendWelcomeEmail(props.id)();
					}}
				>
					Send Welcome Email
				</CustomButton>
			),
		},
		{
			text: "",
			key: "name",
			align: "right",
			notClickable: true,
			customComponent: (props: any) => {
				const notReportsPresent = !props.fullObject?.summaryProposalPdfLink && !props.fullObject?.billAnalysisPdfLink;
				return (
					<CustomTableOptions
						menuOptions={[
							{
								text: "Assign Representative",
								onClick: () => {
									openDialog(props);
								},
							},
							{
								text: "View Report",
								onClick: () => {
									if (!notReportsPresent) {
										downloadPDFFiles([props.fullObject?.summaryProposalPdfLink, props.fullObject?.billAnalysisPdfLink]);
									}
								},
								disabled: notReportsPresent,
								tooltip: notReportsPresent ? "Reports have not been generated for this customer" : "Download Reports",
							},
							{
								text: "Download Summary Proposal",
								onClick: () => {
									console.log(props);
									handleDownload(true, undefined, undefined, props.id, props.text);
								},
							},
						]}
					/>
				);
			},
		},
	];

	const assignedCustomersTableHeaders = [
		...customerTableHeaders,
		{ text: "Representative", key: "representativeName", sortable: true },
		{
			text: "Welcome Email",
			key: "welcomeEmail",
			sortable: true,
			showEllipses: true,
			maxWidth: 130,
			customComponent: (props: any) => (
				<CustomButton sx={{ width: "fit-content" }} variant="outlined" onClick={sendWelcomeEmail(props.id)}>
					Send Welcome Email
				</CustomButton>
			),
		},
		{
			text: "",
			key: "name",
			align: "right",
			notClickable: true,
			customComponent: (props: any) => {
				const notReportsPresent = !props.fullObject?.summaryProposalPdfLink && !props.fullObject?.billAnalysisPdfLink;
				return (
					<CustomTableOptions
						menuOptions={[
							{
								text: "View Logs",
								onClick: () => {
									navigate(allRoutes.LOGS.replace(":id", props.sequentialId));
								},
							},
							{
								text: "Change Status",
								onClick: () => {
									navigate(allRoutes.UPDATE_STATUS.replace(":id", props.sequentialId));
								},
							},
							{
								text: "View Report",
								onClick: () => {
									if (!notReportsPresent) {
										downloadPDFFiles([props.fullObject?.summaryProposalPdfLink, props.fullObject?.billAnalysisPdfLink]);
									}
								},
								disabled: notReportsPresent,
								tooltip: notReportsPresent ? "Reports have not been generated for this customer" : "Download Reports",
							},
							{
								text: "Download Summary Proposal",
								onClick: () => {
									console.log(props);
									handleDownload(true, undefined, undefined, props.id, props.text);
								},
							},
						]}
					/>
				);
			},
		},
	];

	const handleDownload = async (
		isDownloadProposal = false,
		isAssignedCustomers?: boolean,
		isUnAssignedCustomers?: boolean,
		userId?: string,
		userName?: string,
	) => {
		try {
			let response;
			const fileName = isDownloadProposal ? `${userName}'s Summary Proposal.csv` : "Customers.csv";
			const userIdentifier = userId || "";
			if (isDownloadProposal) {
				response = await downloadCustomersProposalCsv(userIdentifier);
			} else if (isAssignedCustomers) {
				response = await downloadAssignedCustomersCsv();
			} else {
				response = await downloadUnAssignedCustomersCsv();
			}

			const url = window.URL.createObjectURL(new Blob([response?.data], { type: "text/csv" }));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error("Error downloading CSV:", error);
		}
	};

	const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (!file) return;

		const formData = new FormData();
		formData.append("file", file);
		setLoading(true);

		try {
			const response = await uploadCustomersCsv(formData);
			toast.success(response?.data?.message || "File uploaded successfully");
			getAllUsers(); // Refresh customer list
		} catch (error: any) {
			toast.error(error?.response?.data?.message || "Error uploading file");
		} finally {
			setLoading(false);
		}
	};

	// Handle downloading sample CSV
	const handleDownloadSampleCSV = async () => {
		try {
			const response = await downloadSampleCsv();
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: "text/csv" }));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "sample_customers.csv");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		} catch (error: any) {
			toast.error(error?.response?.data?.message || "Error downloading sample CSV");
		}
	};

	return (
		<PageLayout loading={loading} hideBackButton>
			{!isTechnician && (
				<>
					<TableBlock
						heading={textSnippets["all_aging_customers_heading_customers_tab"] || "All Aging Customers"}
						subHeading={
							textSnippets["aging_customers_list_description_customers_tab"] || "List of all the aging customers:"
						}
						tableData={data.oldCustomers}
						tableHeaders={customerTableHeaders}
						emptyStateMessage={
							textSnippets["no_aging_customers_message_customers_tab"] || "There are no aging customers"
						}
						detailsPagePath={allRoutes.VIEW_CUSTOMER}
						filterOptions={{
							options: [
								{ value: 60, text: "Last 60 days" },
								{ value: 90, text: "Last 90 days" },
								{ value: 120, text: "Last 120 days" },
							],
							selectedOption: selectedAgingCustomer,
							setSelectedOption: setSelectedAgingCustomer,
							label: "Filter by",
						}}
					/>
					<Divider sx={{ my: 16 }} />
				</>
			)}

			{!isRepresentative && !isTechnician && (
				<>
					<TableBlock
						addButtonText={textSnippets["add_customers_button_unassigned_customers_table"] || "Add Customer"}
						addButtonPath={allRoutes.ADD_CUSTOMER}
						heading={textSnippets["unassigned_customers_heading_customers_tab"] || "Unassigned Customers"}
						subHeading={
							textSnippets["unassigned_customers_list_description_customers_tab"] ||
							"List of all the unassigned customers:"
						}
						tableData={data.unassignedCustomers}
						tableHeaders={unassignedCustomersTableHeader}
						emptyStateMessage={
							textSnippets["no_unassigned_customers_message_customers_tab"] ||
							"There are no unassigned customers available"
						}
						detailsPagePath={allRoutes.VIEW_CUSTOMER}
						isAssignedCustomers
						onDownloadClick={() => handleDownload(false, false)}
						onFileUpload={handleFileUpload}
						onDownloadSampleCSV={handleDownloadSampleCSV}
						isUploadCSv
					/>
					<Divider sx={{ my: 16 }} />
				</>
			)}

			<TableBlock
				heading={textSnippets["assigned_customers_heading_customers_tab"] || "Assigned Customers"}
				subHeading={
					textSnippets["assigned_customers_list_description_customers_tab"] ||
					"List of all the customers that are assigned to a representative:"
				}
				tableData={data.assignedCustomers}
				tableHeaders={assignedCustomersTableHeaders}
				emptyStateMessage={
					textSnippets["no_assigned_customers_message_customers_tab"] || "There are no assigned customers"
				}
				detailsPagePath={allRoutes.VIEW_CUSTOMER}
				isUnAssignedCustomers
				onDownloadClick={() => handleDownload(false, true)}
				onAddCustomer={() => navigate(allRoutes.ADD_CUSTOMER)}
				isRep={true}
			/>

			<AssignRepresentativeDialog
				open={!!assigningCustomer}
				activeUser={assigningCustomer}
				onClose={closeDialog}
				onSuccess={getRemainingCustomers}
			/>
		</PageLayout>
	);
};

export default Customers;
