import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/customer-import-csv";

// =====|  Activation Service  |=====

const ImportCustomerCsvService = {
	uploadCustomersCsv: (data: FormData) =>
		http.post(`${apiEndpoint}`, data, { headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() } }),
	downloadSampleCsv: () =>
		http.get(`${apiEndpoint}/download-sample`, {
			headers: getAuthHeader(),
		}),
};

// =====|  APIs  |=====

export const uploadCustomersCsv = (formData: FormData) => {
	return ImportCustomerCsvService.uploadCustomersCsv(formData);
};

export const downloadSampleCsv = () => {
	return ImportCustomerCsvService.downloadSampleCsv();
};
