import { Close as CloseIcon } from "@mui/icons-material";
import { Box, FormControlLabel, IconButton, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { uploadCustomersCsv } from "../../Services/importCustomerCsv";
import CustomButton from "./CustomButton";
import CustomDialog from "./CustomDialog";

interface CsvUploaderProps {
	open: boolean;
	onClose: () => void;
}

const CsvUploader: React.FC<CsvUploaderProps> = ({ open, onClose }) => {
	const [file, setFile] = useState<File | null>(null);
	const [sendWelcomeEmail, setSendWelcomeEmail] = useState<boolean>(false);

	useEffect(() => {
		// Clear file when modal closes
		if (!open) {
			setFile(null);
		}
	}, [open]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			setFile(event.target.files[0]);

			// Reset input value to allow re-selecting the same file
			event.target.value = "";
		}
	};

	const handleRemoveFile = () => {
		setFile(null);
	};

	const handleUpload = async () => {
		if (!file) {
			toast.error("Please select a file first!");
			return;
		}

		const formData = new FormData();
		formData.append("file", file);
		formData.append("isWelcomeEmail", sendWelcomeEmail.toString());

		try {
			const response = await uploadCustomersCsv(formData);
			toast.success(response?.data?.message || "File uploaded successfully");
			onClose();
		} catch (error) {
			toast.error("Upload failed!");
		}
	};

	const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSendWelcomeEmail(event.target.checked);
	};

	return (
		<CustomDialog open={open} onClose={onClose}>
			<Typography variant="h5" fontWeight={600} gutterBottom>
				Upload CSV File
			</Typography>

			<Stack spacing={8}>
				{/* File Input */}
				<Box>
					<input accept=".csv" style={{ display: "none" }} id="file-input" type="file" onChange={handleFileChange} />
					<label htmlFor="file-input">
						<CustomButton sx={{ marginTop: "10px" }} fullWidth variant="contained" color="primary" component="span">
							Select CSV File
						</CustomButton>
					</label>

					{/* Selected File Display */}
					{file && (
						<Box
							display="flex"
							alignItems="center"
							mt={8}
							px={2}
							py={1}
							sx={{
								border: "1px solid #ddd",
								borderRadius: 2,
								backgroundColor: "#f9f9f9",
							}}
						>
							<Typography variant="body2" color="textSecondary" sx={{ flexGrow: 1 }}>
								{file.name}
							</Typography>
							<IconButton onClick={handleRemoveFile} size="small">
								<CloseIcon fontSize="small" />
							</IconButton>
						</Box>
					)}
				</Box>

				{/* Toggle Switch */}
				<FormControlLabel
					control={<Switch checked={sendWelcomeEmail} onChange={handleToggleChange} color="primary" />}
					label="Send Welcome Email"
				/>

				{/* Action Buttons */}
				<Stack sx={{ marginTop: "10px" }} direction="row" spacing={10} justifyContent="flex-end">
					<CustomButton variant="outlined" color="secondary" onClick={onClose}>
						Cancel
					</CustomButton>
					<CustomButton variant="contained" color="primary" onClick={handleUpload}>
						Upload
					</CustomButton>
				</Stack>
			</Stack>
		</CustomDialog>
	);
};

export default CsvUploader;
